import CircularProgress from "@mui/material/CircularProgress";
import { useMemo } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Button } from "../components/Button";
import { PageContainer } from "../components/PageContainer";
import { TopBar } from "../components/TopBar";
import { ComboBoxFieldConnected } from "../form-components/ComboBoxFieldConnected";
import { TextFieldConnected } from "../form-components/TextFieldConnected";

interface Fields {
  productName: string;
  manufacturer: string;
  language: string;
}

export const SearchForm = ({
  onSubmit,
  loading,
}: {
  onSubmit: (props: { productName: string; manufacturer: string; language: string }) => void;
  loading: boolean;
}) => {
  const { t } = useTranslation();

  const methods = useForm<Fields>({
    defaultValues: {
      productName: "",
      manufacturer: "",
      language: "en",
    },
  });

  return (
    <FormProvider {...methods}>
      <PageContainer
        $as="form"
        className="space-y-10"
        onSubmit={methods.handleSubmit(onSubmit)}
        noValidate
      >
        <TopBar
          title={t("Search")}
          input={
            <Button type="submit" isDisabled={loading}>
              {loading && <CircularProgress size="24px" />}
              {t("Search")}
            </Button>
          }
        />
        <div className="grid grid-cols-[100px_1fr_1fr] gap-x-6">
          <ComboBoxFieldConnected
            label={t("Language")}
            name="language"
            options={useMemo(
              () => [
                { id: "en", label: "English" },
                { id: "de", label: "German" },
              ],
              [],
            )}
          />
          <TextFieldConnected name="productName" label={t("Product Name")} />
          <TextFieldConnected name="manufacturer" label={t("Manufacturer")} />
        </div>
      </PageContainer>
    </FormProvider>
  );
};
