import { CircularProgress } from "@mui/material";
import { useMemo } from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import useDebounce from "react-use/lib/useDebounce";
import { SupplierMaterial } from "../../../../api/types";
import { Alert } from "../../../../components/Alert";
import { Heading3, Text14 } from "../../../../components/Typography";
import { useElementaries } from "../../../../state/elementaries";
import { useSearchEPDs } from "../../../../state/search";
import { FormFields, SecondaryDatasetWithOptions } from "../types";
import { PrimaryData } from "./PrimaryData";
import { SecondaryData } from "./SecondaryData";

const useEnvironmentalDataSearch = (
  productName?: string,
  supplierName?: string,
  existingMaterial?: SupplierMaterial | null,
) => {
  const { data: primaryData = [], isPending, mutate: search } = useSearchEPDs();

  const methods = useFormContext<FormFields>();

  useDebounce(
    () => {
      if (!productName || !supplierName) return;

      search({
        searchTerm: [productName, supplierName].join(" ").trim(),
        language: "en",
        maxResults: 10,
      });

      if (
        !existingMaterial ||
        (existingMaterial.name !== productName &&
          existingMaterial.supplier_plant?.supplier_company.name !== supplierName)
      ) {
        methods.setValue("epdId", null);
      }
    },
    existingMaterial ? 0 : 500,
    [productName, supplierName, existingMaterial],
  );

  return { primaryData, isPending };
};

export const LinkEnvironmentalData = ({
  existingMaterial,
}: {
  existingMaterial?: SupplierMaterial | null;
}) => {
  const { t } = useTranslation();
  const methods = useFormContext<FormFields>();

  const { elementariesMap } = useElementaries();
  const elementaryId = methods.watch("elementaryId");
  const epdId = methods.watch("epdId");

  const materialMatching = elementariesMap[elementaryId]?.matching;

  const productName = methods.watch("productName");
  const supplierCompanyName = methods.watch("supplierCompanyName");

  const supplierName = existingMaterial
    ? existingMaterial.supplier_plant?.supplier_company.name
    : supplierCompanyName;

  const { primaryData, isPending } = useEnvironmentalDataSearch(
    productName,
    supplierName,
    existingMaterial,
  );

  const linkedSecondaryData: SecondaryDatasetWithOptions[] = useMemo(
    () =>
      existingMaterial?.secondary_data ||
      materialMatching?.map((match) => ({ activity_name: match[0] })) ||
      [],
    [existingMaterial, materialMatching],
  );

  return (
    <>
      <Heading3>
        {t("Link Environmental Data")}
        {isPending && <CircularProgress size="24px" />}
      </Heading3>
      {primaryData.length > 0 && <PrimaryData primaryData={primaryData} />}
      {linkedSecondaryData.length > 0 && (
        <SecondaryData data={linkedSecondaryData} hasOtherOptions={primaryData.length > 0} />
      )}
      {!epdId && linkedSecondaryData.length > 0 && (
        <Alert intent="info">
          <Text14>
            {primaryData.length === 0 && t("No EPD data found.")}{" "}
            {t("Using conservative data from secondary databases.")}
            <br />
            {t(
              "Request EPD from your supplier to improve accuracy of environmental impact calculation.",
            )}
          </Text14>
        </Alert>
      )}
    </>
  );
};
