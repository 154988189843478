import { Article, LaunchOutlined } from "@mui/icons-material";
import { FormControlLabel, Radio } from "@mui/material";
import { Controller, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { IconContainer } from "../../../../components/IconContainer";
import { InfoTooltip } from "../../../../components/InfoTooltip";
import { Link } from "../../../../components/Link";
import { Label } from "../../../../components/Typography";
import { FormFields, SecondaryDatasetWithOptions } from "../types";

const SecondaryDataItem = ({ item }: { item: SecondaryDatasetWithOptions }) => {
  const { t } = useTranslation();

  return (
    <>
      <Link intent="link" size="small" href={item.source_url} target="_blank">
        {item.activity_name}
        {!!item.source_url && <LaunchOutlined className="text-neutral-500" fontSize="small" />}
      </Link>
      <p className="text-sm">
        {t("Data source")}: Ecoinvent 3.10 {item.geography && `(${item.geography})`}
      </p>
    </>
  );
};

export const SecondaryData = ({
  data,
  hasOtherOptions,
}: {
  data: SecondaryDatasetWithOptions[];
  hasOtherOptions?: boolean;
}) => {
  const { t } = useTranslation();
  const methods = useFormContext<FormFields>();

  return (
    <div>
      <Label className="flex items-center gap-2">
        {t("Secondary Data")}
        <InfoTooltip>
          {t(
            `Secondary data is the life cycle inventory (LCI) data provided by existing databases.`,
          )}
        </InfoTooltip>
      </Label>
      <div className="flex flex-col gap-2 mt-4">
        <div className="flex gap-4 items-center max-w-full">
          <IconContainer $pale className="shrink-0">
            <Article />
          </IconContainer>
          <div className="flex flex-col flex-1">
            {data.map((item) => (
              <SecondaryDataItem key={item.activity_name} item={item} />
            ))}
          </div>
          {hasOtherOptions && (
            <Controller
              name="epdId"
              control={methods.control}
              render={({ field: { value, ...field } }) => (
                <FormControlLabel
                  id="field-secondary"
                  className="text-blue-600"
                  labelPlacement="start"
                  control={<Radio {...field} checked={value === null || value === ""} />}
                  label={t("Select")}
                />
              )}
            />
          )}
        </div>
      </div>
    </div>
  );
};
