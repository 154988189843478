import { CheckCircle } from "@mui/icons-material";
import { useMemo } from "react";
import { Link } from "react-router-dom";
import tw from "tailwind-styled-components";
import type { Product, ProductCategory } from "../../api/types";
import { Heading4, Text16 } from "../../components/Typography";
import { CATEGORIES_WITHOUT_RECIPE } from "../../state/HARD_CODED";
import { useGetLinkWithParams } from "../../url/useGetLinkWithParams";
import { ProductStatusIndicator } from "./ProductStatusIndicator";

const CardContainer = tw(Link)<{ $isSelectMode?: boolean; $isSelected?: boolean }>`
  ${({ $isSelected, $isSelectMode }) =>
    $isSelectMode
      ? $isSelected
        ? "border-2 border-steelblue"
        : "border-2 border-dashed border-neutral-300 hover:border-solid hover:border-builtgreen"
      : "border border-neutral-300 hover:border-energyyellow"}

  rounded-2xl
  p-6

  shadow-e-xs hover:shadow-e-sm active:shadow-e-xs
  translate-y-0 hover:translate-y-[-1px] active:translate-y-0
  transition-all

  cursor-pointer

  flex
  flex-col
  justify-between
  gap-3
  bg-white
  h-[220px]
  overflow-hidden
`;

export const ProductCard = ({
  item,
  productCategoriesMap,
  isSelected,
  isSelectMode,
  onToggleSelect,
}: {
  item: Product;
  productCategoriesMap: Record<ProductCategory["id"], ProductCategory>;
  isSelected?: boolean;
  isSelectMode?: boolean;
  onToggleSelect: () => void;
}) => {
  const getLinkWithParams = useGetLinkWithParams();

  const mainLinkTo = useMemo(() => {
    if (item.status !== "incomplete") {
      return `/products/${item.id}`;
    }

    if (!item.tech_specs || (item.tech_specs instanceof Array && item.tech_specs.length === 0))
      return getLinkWithParams("/edit/product-specs", { selectedProduct: item.id });

    if (!item.production_process_id) {
      return getLinkWithParams("/edit/product-production-process", { selectedProduct: item.id });
    }

    if (!CATEGORIES_WITHOUT_RECIPE.includes(item.category.id)) {
      if (!item.recipe || (item.recipe instanceof Array && item.recipe.length === 0)) {
        return getLinkWithParams("/edit/product-recipe", { selectedProduct: item.id });
      }
    }

    return getLinkWithParams("/edit/product-details", { selectedProduct: item.id });
  }, [item, getLinkWithParams]);

  return (
    <>
      <CardContainer
        $as={isSelectMode ? "div" : Link}
        to={mainLinkTo}
        unstable_viewTransition
        className="group relative"
        $isSelectMode={isSelectMode}
        $isSelected={isSelected}
        onClick={isSelectMode ? onToggleSelect : undefined}
        style={{
          viewTransitionName: `product-${item.id}`,
        }}
      >
        <ProductStatusIndicator status={item.status} />
        {isSelectMode && isSelected && (
          <CheckCircle className="absolute top-6 right-6 text-steelblue" fontSize="small" />
        )}
        <div className="flex flex-col gap-1">
          <Heading4 className="break-words">{item.name}</Heading4>
          <Text16 className="text-neutral-500">
            {productCategoriesMap[item.category.id]?.name.en}
          </Text16>
        </div>
      </CardContainer>
    </>
  );
};
