import { useMutation } from "@tanstack/react-query";
import { usePostSearchResults } from "../api/endpoints/search";

export const useSearchEPDs = () => {
  const postSearchResults = usePostSearchResults();

  return useMutation({
    mutationFn: (params: { searchTerm: string; language?: string; maxResults?: number }) =>
      postSearchResults(params),
  });
};
