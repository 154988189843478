import { ArrowBack, RotateRight } from "@mui/icons-material";
import { CircularProgress } from "@mui/material";
import { Dispatch, SetStateAction, Suspense } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Product } from "../../api/types";
import { Button } from "../../components/Button";
import { TabSwitch } from "../../components/TabSwitch";
import { Tooltip, TooltipTrigger } from "../../components/Tooltip";
import { TopBar } from "../../components/TopBar";
import { useDeclarations } from "../../state/declarations";
import { useUpdateProductLca } from "../../state/products";
import { showSuccessToast } from "../../util/toasts";
import { useProductStatus } from "../products/ProductStatusIndicator";

export const ProductDetailTopBar = ({
  product,
  activeTab,
  setActiveTab,
  onEdit,
  onOpenSidePanel,
}: {
  product: Product;
  activeTab: "passport" | "analyse";
  setActiveTab: Dispatch<SetStateAction<"passport" | "analyse">>;
  onEdit: () => void;
  onOpenSidePanel: () => void;
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { mutate: updateLca, isPending: isUpdating } = useUpdateProductLca();
  const onUpdateLca = () => {
    updateLca(
      {
        manufacturerId: product.manufacturer_id,
        plantId: product.plant_id,
        productId: product.id,
      },
      {
        onSuccess: () => {
          showSuccessToast(t("Successfully recalculated LCA data and documents."));
        },
      },
    );
  };

  const productHasDelcaration = product.status === "under-review" || product.status === "completed";

  return (
    <TopBar
      variant="concrete"
      icon={
        <Button
          intent="secondary"
          square
          onPress={() => navigate("/", { unstable_viewTransition: true })}
        >
          <ArrowBack />
        </Button>
      }
      title={t("Product details")}
      subtitle={
        <span className="flex items-center gap-2">
          <span>{t("Status")}:</span>
          <span>{useProductStatus(product.status)}</span>
        </span>
      }
      extra={
        <TabSwitch
          options={[
            { label: "Passport", value: "passport" as const },
            { label: "Analyse", value: "analyse" as const },
          ]}
          activeTab={activeTab}
          setActiveTab={(tab) => setActiveTab(tab as "passport" | "analyse")}
        />
      }
      input={
        <>
          {productHasDelcaration || (
            <div>
              <TooltipTrigger>
                <Button intent="tertiaryFlat" onPress={onUpdateLca}>
                  {t("Renew documents")}
                  {isUpdating ? <CircularProgress size="24px" /> : <RotateRight />}
                </Button>
                <Tooltip>
                  {t(
                    "Click here to recalculate the LCA and re-generate all related documents for this product",
                  )}
                </Tooltip>
              </TooltipTrigger>
            </div>
          )}
          {(product.status === "draft" || product.status === "incomplete") && (
            <Button intent="secondary" onPress={onEdit}>
              {t("Edit")}
            </Button>
          )}
          {(product.documents.length > 0 || productHasDelcaration) && (
            <Suspense>
              <MainButton onPress={onOpenSidePanel} product={product} />
            </Suspense>
          )}
        </>
      }
    />
  );
};

const MainButton = ({ product, onPress }: { product: Product; onPress: () => void }) => {
  const { t } = useTranslation();

  // Pre-laod the declaration
  useDeclarations({
    productId: product.id,
    plantId: product.plant_id,
    manufacturerId: product.manufacturer_id,
  });

  return <Button onPress={onPress}>{t("Documents")}</Button>;
};
