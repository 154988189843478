import { Article, LaunchOutlined } from "@mui/icons-material";
import { FormControlLabel, Radio } from "@mui/material";
import { Controller, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { EPDSearchResult } from "../../../../api/types";
import { IconContainer } from "../../../../components/IconContainer";
import { InfoTooltip } from "../../../../components/InfoTooltip";
import { Link } from "../../../../components/Link";
import { Label } from "../../../../components/Typography";
import { FormFields } from "../types";

const PrimaryDataItem = ({ item }: { item: EPDSearchResult }) => {
  const { t } = useTranslation();
  const methods = useFormContext<FormFields>();

  return (
    <label
      htmlFor={`field-${item.id}`}
      key={item.id}
      className="flex gap-4 items-center max-w-full"
    >
      <IconContainer $active $blue className="shrink-0">
        <Article />
      </IconContainer>
      <div className="flex flex-col flex-1">
        <Link
          intent="link"
          size="small"
          href={item.item_source_url || item.epd_pdf_url}
          target="_blank"
        >
          {item.name_en}
          {(item.item_source_url || item.epd_pdf_url) && (
            <LaunchOutlined className="text-neutral-500" fontSize="small" />
          )}
        </Link>
        <p className="text-sm line-clamp-1 shrink-0">
          {item.manufacturer}, {item.reference_year}
        </p>
      </div>
      <Controller
        name="epdId"
        control={methods.control}
        render={({ field: { value, ...field } }) => (
          <FormControlLabel
            id={`field-${item.id}`}
            value={item.id}
            className="text-blue-600"
            labelPlacement="start"
            disabled={!item.can_be_used}
            control={<Radio {...field} checked={value === item.id} defaultChecked={false} />}
            label={item.can_be_used ? t("Select") : t("Can't select")}
          />
        )}
      />
    </label>
  );
};

export const PrimaryData = ({ primaryData }: { primaryData: EPDSearchResult[] }) => {
  const { t } = useTranslation();

  return (
    <div className="flex flex-col gap-2">
      <Label className="flex items-center gap-2">
        {t("EPD data")}
        <InfoTooltip>
          {t(`EPD data is the life cycle inventory (LCI) data provided by published EPDs.`)}
        </InfoTooltip>
      </Label>
      {primaryData.map((item) => (
        <PrimaryDataItem key={item.id} item={item} />
      ))}
    </div>
  );
};
