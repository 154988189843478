import { VisibilityOutlined } from "@mui/icons-material";
import { DialogTrigger } from "react-aria-components";
import { useTranslation } from "react-i18next";
import { Product } from "../../api/types";
import { Badge } from "../../components/Badge";
import { Button } from "../../components/Button";
import { Popover } from "../../components/Popover";
import { Heading2, Text14 } from "../../components/Typography";
import { useElementaries } from "../../state/elementaries";
import { TechSpecs } from "./TechSpecs";

export const ProductSummary = ({ product }: { product: Product }) => {
  const { t } = useTranslation();

  const { elementariesMap } = useElementaries();
  const elementaryLabel = elementariesMap[product.elementary_id]?.name_en;

  return (
    <div className="flex items-start justify-between gap-10">
      <div className="flex flex-col gap-3 max-w-2xl">
        <div className="grid grid-cols-[auto_1fr] items-center gap-5">
          <Heading2 className="line-clamp-1 [word-wrap:break-word]" title={product.name}>
            {product.name}
          </Heading2>
          {elementaryLabel && <Badge className="whitespace-nowrap">{elementaryLabel}</Badge>}
        </div>
        {product.description && <Text14 className="line-clamp-2">{product.description}</Text14>}
      </div>
      <div className="flex-shrink-0">
        <DialogTrigger>
          <Button intent="tertiary">
            {t("Show technical specs")} <VisibilityOutlined />
          </Button>
          <Popover>
            <TechSpecs product={product} />
          </Popover>
        </DialogTrigger>
      </div>
    </div>
  );
};
