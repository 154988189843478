import { AddOutlined, UploadOutlined } from "@mui/icons-material";
import { CircularProgress } from "@mui/material";
import { useQueryClient } from "@tanstack/react-query";
import { useState } from "react";
import { FileTrigger } from "react-aria-components";
import { useTranslation } from "react-i18next";
import { useBulkUploadProducts } from "../../api/endpoints/products";
import { Product } from "../../api/types";
import { Button } from "../../components/Button";
import { Link } from "../../components/Link";
import { useActiveManufacturer } from "../../state/manufacturers";
import { useActivePlant } from "../../state/plants";
import { showSuccessToast } from "../../util/toasts";

const useUploadProducts = () => {
  const { activeManufacturer } = useActiveManufacturer();
  const activePlant = useActivePlant();
  const queryClient = useQueryClient();

  const bulkUploadProducts = useBulkUploadProducts();
  const [uploadLoading, setUploadLoading] = useState(false);

  const handleUpload = async (files: FileList | null) => {
    if (!files) return;

    setUploadLoading(true);

    const { imported, updated, skipped, failed, errors } = await bulkUploadProducts({
      manufacturerId: activeManufacturer.id,
      file: files[0],
    });

    showSuccessToast(
      `Products uploaded successfully. Imported: ${imported}, Updated: ${updated}, Skipped: ${skipped}, Failed: ${failed} \n\n${errors.join("\n")}`,
    );

    queryClient.invalidateQueries({
      queryKey: [activeManufacturer.id, activePlant.id, "products"],
    });

    setUploadLoading(false);
  };

  return {
    handleUpload,
    uploadLoading,
  };
};

export const TopBarInput = ({
  selectedProducts,
  isSelectMode,
  onCreateDocuments,
}: {
  selectedProducts: Set<Product>;
  isSelectMode: boolean;
  onCreateDocuments: () => void;
}) => {
  const { t } = useTranslation();
  const { handleUpload, uploadLoading } = useUploadProducts();

  if (isSelectMode) {
    return (
      <Button
        intent="secondary"
        isDisabled={
          selectedProducts.size === 0 ||
          // We don't support more than 14 product in the bulk PCF,
          // because then the summary page breaks into mulitple pages
          selectedProducts.size > 14 ||
          [...selectedProducts].some((p) => p.status === "incomplete")
        }
        onPress={onCreateDocuments}
      >
        {t("Create New Documents")}
      </Button>
    );
  }

  return (
    <>
      <FileTrigger onSelect={handleUpload}>
        <Button intent="secondary" isDisabled={uploadLoading}>
          {uploadLoading ? <CircularProgress size="24px" /> : <UploadOutlined />}
          {t("Upload Products")}
        </Button>
      </FileTrigger>
      <Link href="/edit/product-details">
        <AddOutlined />
        {t("New Product")}
      </Link>
    </>
  );
};
