import { CloseOutlined, DeleteOutlined } from "@mui/icons-material";
import { DialogTrigger } from "react-aria-components";
import { useTranslation } from "react-i18next";
import { Product } from "../../api/types";
import { Button } from "../../components/Button";
import { CheckboxField } from "../../components/CheckboxField";
import { DeleteProductsModal } from "./DeleteProductsModal";
import { useProductBulkDeletion } from "./useProductBulkDeletion";
import type { useProductSelection } from "./useProductSelection";

export const MultiSelectActionBar = ({
  selectedProducts,
  products,
  isSelectMode,
  toggleSelectMode,
  toggleSelectAll,
  resetSelectedProducts,
}: {
  products: Product[];
} & Pick<
  ReturnType<typeof useProductSelection>,
  | "selectedProducts"
  | "isSelectMode"
  | "toggleSelectMode"
  | "toggleSelectAll"
  | "resetSelectedProducts"
>) => {
  const { t } = useTranslation();
  const { onDeleteSelectedProducts } = useProductBulkDeletion({ selectedProducts });

  return (
    <div className="flex items-center justify-between gap-4">
      <div className="flex items-center gap-4 h-10">
        <CheckboxField onChange={toggleSelectMode} isSelected={isSelectMode}>
          {isSelectMode
            ? t("{{ count }} selected", { count: selectedProducts.size })
            : t("Select products")}
        </CheckboxField>
        {isSelectMode && (
          <Button intent="tertiary" size="small" onPress={toggleSelectAll}>
            {selectedProducts.size < products.length ? t("Select all") : t("Unselect all")}
          </Button>
        )}
      </div>
      <div className="flex items-center gap-2">
        {isSelectMode && selectedProducts.size > 0 && (
          <DialogTrigger>
            <Button intent="tertiaryFlat" size="small">
              {t("Delete {{ count }} products", { count: selectedProducts.size })}
              <DeleteOutlined />
            </Button>
            <DeleteProductsModal
              selectedProducts={selectedProducts}
              onSubmit={async (close) => {
                await onDeleteSelectedProducts();
                close();
                resetSelectedProducts();
                toggleSelectMode();
              }}
            />
          </DialogTrigger>
        )}
        {isSelectMode && (
          <Button intent="tertiaryFlat" size="small" onPress={toggleSelectMode}>
            {t("Cancel")}
            <CloseOutlined />
          </Button>
        )}
      </div>
    </div>
  );
};
