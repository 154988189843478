import { useState } from "react";
import { SearchForm } from "../page-components/SearchForm";
import { SearchResults } from "../page-components/SearchResults";
import { useSearchEPDs } from "../state/search";

interface SearchFormValues {
  productName: string;
  manufacturer: string;
  language?: string;
}

export const Search = () => {
  const [searchedLanguage, setSearchedLanguage] = useState<string | undefined>(undefined);
  const { mutate: search, isPending, data: searchResults } = useSearchEPDs();

  const handleSubmit = (values: SearchFormValues) => {
    const language = values.language?.toLowerCase();

    setSearchedLanguage(language);

    search({
      searchTerm: [values.productName, values.manufacturer].join(" ").trim(),
      language,
    });
  };

  return (
    <div className="flex flex-col gap-6 pb-8">
      <SearchForm onSubmit={handleSubmit} loading={isPending} />
      <SearchResults results={searchResults || []} searchedLanguage={searchedLanguage} />
    </div>
  );
};
