import { CircularProgress } from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Document, Product } from "../../api/types";
import { SidePanel } from "../../components/SidePanel";
import { Label12, Label16 } from "../../components/Typography";
import { useDocumentBulkPCF } from "../../state/products";
import { ViewOrDownloadDocument } from "../ViewOrDownloadDocument";

export const DocumentsSidePanel = ({
  selectedProducts,
  open,
  onClose,
}: {
  selectedProducts: Set<Product>;
  open: boolean;
  onClose: () => void;
}) => {
  const { t } = useTranslation();
  const { mutateAsync: documentBulkPCF, isPending } = useDocumentBulkPCF();

  const [pcfDocument, setPcfDocument] = useState<Document | null>(null);

  useEffect(() => {
    const createBulkPcf = async () => {
      setPcfDocument(null);
      const document = await documentBulkPCF({
        manufacturerId: [...selectedProducts][0].manufacturer_id,
        plantId: [...selectedProducts][0].plant_id,
        productIds: [...selectedProducts].map((p) => p.id),
      });
      setPcfDocument(document);
    };

    if (open) createBulkPcf();
  }, [documentBulkPCF, open, selectedProducts]);

  return (
    <SidePanel title={t("Documents")} open={open} onClose={onClose}>
      <div className="space-y-4">
        <Label12>1 {t("Document")}</Label12>
        <div className="first-of-type:border-t border-b py-2 border-neutral-300 flex items-center justify-between gap-2">
          <Label16>{t("PCF Overview")}</Label16>
          <div className="h-10">
            {isPending && (
              <div className="flex justify-center items-center h-full">
                <CircularProgress size="24px" />
              </div>
            )}
            {!isPending && pcfDocument && <ViewOrDownloadDocument doc={pcfDocument} />}
          </div>
        </div>
      </div>
    </SidePanel>
  );
};
