import { Add, Clear, HomeWorkOutlined, LinkOutlined } from "@mui/icons-material";
import { Fragment, useMemo, useState } from "react";
import { useFieldArray, useFormContext, useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { PlantMaterial } from "../../api/types";
import { Button } from "../../components/Button";
import { Card } from "../../components/Card";
import { ComboBoxField } from "../../components/ComboBoxField";
import { Label } from "../../components/Typography";
import { ComboBoxFieldConnected } from "../../form-components/ComboBoxFieldConnected";
import { NumberFieldConnected } from "../../form-components/NumberFieldConnected";
import { exists } from "../../util/commonUtil";
import { ProductRecipeForm, ProductRecipeMaterialOption } from "./types";

export const combineIds = ({
  supplierProductId,
  prechainProductId,
}: {
  supplierProductId?: string | null;
  prechainProductId?: string | null;
}) => {
  if (!supplierProductId && !prechainProductId) return null;
  return `${supplierProductId || ""}--${prechainProductId || ""}`;
};

export const splitIds = (id: string | null) => {
  if (!id) return {};
  const [supplierProductId, prechainProductId] = id.split("--");
  return {
    supplierProductId: supplierProductId || undefined,
    prechainProductId: prechainProductId || undefined,
  };
};

export const ProductRecipeFields = ({
  materialOptions,
  materials,
}: {
  materialOptions: ProductRecipeMaterialOption[];
  materials: PlantMaterial[];
}) => {
  const { t } = useTranslation();
  const { control } = useFormContext<ProductRecipeForm>();
  const { fields, append, remove } = useFieldArray<ProductRecipeForm>({
    control,
    name: "materials",
  });

  const [adding, setAdding] = useState(false);

  const formMaterials = useWatch({ control, name: "materials" });

  const controlledFields = fields.map((field, index) => {
    return {
      ...field,
      ...formMaterials[index],
    };
  });

  const availableMaterialOptions = useMemo(() => {
    const unusedSupplierMaterials = materials.filter(
      (mat) =>
        !formMaterials.some((material) => {
          const { supplierProductId, prechainProductId } = splitIds(material.input);

          return (
            (mat.prechain_product && prechainProductId === mat.prechain_product?.id) ||
            (mat.supplier_product && supplierProductId === mat.supplier_product?.id)
          );
        }),
    );

    return materialOptions
      .filter((materialOption) =>
        unusedSupplierMaterials.some(
          (m) =>
            m.supplier_product?.elementary_id === materialOption.elementaryId ||
            m.prechain_product?.product?.elementary_id === materialOption.elementaryId,
        ),
      )
      .map((option) => ({
        id: option.elementaryId,
        label: option.label,
        isDisabled: !option.isAllowedInCategory,
      }));
  }, [materialOptions, materials, formMaterials]);

  return (
    <div className="flex-grow overflow-y-auto py-2">
      <Card
        footerSection={
          <>
            {!adding && availableMaterialOptions.length > 0 && (
              <Button intent="tertiary" onPress={() => setAdding(true)}>
                <Add />
                {t("Add Ingredient")}
              </Button>
            )}
            {adding && (
              <ComboBoxField
                onChange={(_, opt) => {
                  if (!opt) return;
                  const option = materialOptions.find((o) => o.elementaryId === opt.id);
                  if (option) {
                    append({ ...option, input: null, value: null });
                    setAdding(false);
                  }
                }}
                value={null}
                disablePortal
                options={availableMaterialOptions}
                label={t("Material")}
              />
            )}
          </>
        }
      >
        {controlledFields.length === 0 && (
          <Label className="text-center pt-6">{t("No ingredients added yet")}</Label>
        )}
        <div className="py-3 grid grid-cols-[1fr_1fr_auto] gap-6">
          {controlledFields.map((opt, idx) => {
            const rawMaterialOptions = materials
              .filter(
                (material) =>
                  material.prechain_product?.product?.elementary_id === opt.elementaryId ||
                  material.supplier_product?.elementary_id === opt.elementaryId,
              )
              .filter((material) => {
                const { supplierProductId, prechainProductId } = splitIds(opt.input);

                // Check if the material is linked to this field or unused
                return (
                  (material.supplier_product?.id === supplierProductId &&
                    material.prechain_product?.id === prechainProductId) ||
                  !controlledFields.some((field) => {
                    const ids = splitIds(field.input);
                    return (
                      ids.supplierProductId === material.supplier_product?.id &&
                      ids.prechainProductId === material.prechain_product?.id
                    );
                  })
                );
              })
              .map((material) => {
                const id = combineIds({
                  supplierProductId: material.supplier_product?.id,
                  prechainProductId: material.prechain_product?.id,
                });

                if (!id) return null;

                const supplierName =
                  material.supplier_product?.supplier_plant?.supplier_company?.name ||
                  material.prechain_product?.plant_name;
                const productName =
                  material.prechain_product?.product?.name || material.supplier_product?.name;

                const hasPrimaryData =
                  !!material.supplier_product?.epd_id || !!material.prechain_product;

                let icon;
                if (hasPrimaryData) {
                  icon = material.prechain_product ? <HomeWorkOutlined /> : <LinkOutlined />;
                }

                return {
                  id,
                  icon,
                  label: [supplierName, productName].filter(exists).join(" – "),
                };
              })
              .filter(exists);

            return (
              <Fragment key={opt.id}>
                <NumberFieldConnected<ProductRecipeForm>
                  key={opt.id}
                  name={`materials.${idx}.value`}
                  inputProps={{ addonRight: opt.unit }}
                  label={opt.label}
                />
                <ComboBoxFieldConnected
                  name={`materials.${idx}.input`}
                  options={rawMaterialOptions}
                  label={t("Linked Supplier Material")}
                />
                <Button intent="secondary" onPress={() => remove(idx)} square>
                  <Clear />
                </Button>
              </Fragment>
            );
          })}
        </div>
      </Card>
    </div>
  );
};
