import { EndOfScale, Product } from "../../api/types";
import { Loading } from "../../components/Loading";
import { BreakdownPerStage } from "./BreakdownPerStage";
import { EnvironmentalPerformanceIndicators } from "./EnvironmentalPerformanceIndicators";
import { ProductImage } from "./ProductImage";
import { ProductStats } from "./ProductStats";
import { ProductSummary } from "./ProductSummary";

export const ProductDetailPagePassport = ({
  product,
  endsOfScale,
  isUpdating,
}: {
  product: Product;
  endsOfScale: EndOfScale[];
  isUpdating?: boolean;
}) => {
  return (
    <div className="flex flex-col pb-8 gap-8 flex-grow">
      <div className="flex flex-col gap-8 flex-grow">
        <div className="-mx-6 px-6 pt-8 pb-4 bg-concrete bg-texture flex gap-6">
          <ProductImage product={product} />
          <div className="flex flex-col">
            <ProductSummary product={product} />

            <hr className="border-t mt-6 mb-4 border-neutral-300" />
            {product.lca_results && (
              <ProductStats lciaResults={product.lca_results} baseUnit={product.unit} />
            )}
          </div>
        </div>
        <div className="flex flex-col gap-8 flex-grow">
          {product.lca_results && (
            <>
              <EnvironmentalPerformanceIndicators
                impactSummary={product.lca_results.impact_summary}
                endsOfScale={endsOfScale}
              />
              <hr className="border-t border-neutral-300 mb-12" />
              <BreakdownPerStage impactSummary={product.lca_results.impact_summary} />
            </>
          )}
          {!product.lca_results && isUpdating && <Loading />}
        </div>
      </div>
    </div>
  );
};
