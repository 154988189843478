import { useSuspenseQuery } from "@tanstack/react-query";
import { useGetProgramOperators } from "../api/endpoints/program_operators";

export const useProgramOperators = () => {
  const getProgramOperators = useGetProgramOperators();

  const { data } = useSuspenseQuery({
    queryKey: ["programOperators"],
    queryFn: getProgramOperators,
    select: (programOperators) => {
      return {
        programOperators,
        programOperatorsMap: Object.fromEntries(programOperators.map((po) => [po.id, po])),
      };
    },
  });

  return data;
};
