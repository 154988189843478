import { WindowOutlined } from "@mui/icons-material";
import { Suspense, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import tw from "tailwind-styled-components";
import { Loading } from "../components/Loading";
import { PageContainer } from "../components/PageContainer";
import { TopBar } from "../components/TopBar";
import { DocumentsSidePanel } from "../page-components/products/DocumentsSidePanel";
import { EmptyProductList } from "../page-components/products/EmptyProductList";
import { MultiSelectActionBar } from "../page-components/products/MultiSelectActionBar";
import { ProductCard } from "../page-components/products/ProductCard";
import { TopBarInput } from "../page-components/products/TopBarInput";
import { useProductSelection } from "../page-components/products/useProductSelection";
import { useActiveOnboardingStep } from "../state/onboarding";
import { useActivePlant } from "../state/plants";
import { useProductCategories } from "../state/productCategories";
import { useActivePlantProducts } from "../state/products";

const Content = tw("div")`
  grid
  gap-5
  grid-cols-1 md:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-4
`;

export const Products = () => {
  const activePlant = useActivePlant();

  const { t } = useTranslation();

  const { data: products } = useActivePlantProducts();
  const count = products.length;

  const {
    data: { productCategoriesMap },
  } = useProductCategories();

  const {
    isSelectMode,
    selectedProducts,
    toggleSelect,
    toggleSelectMode,
    toggleSelectAll,
    resetSelectedProducts,
  } = useProductSelection({ products });

  useEffect(
    function resetSelectionOnPlantChange() {
      resetSelectedProducts();
    },
    [activePlant, resetSelectedProducts],
  );

  const navigate = useNavigate();
  const { step: onboardingStep } = useActiveOnboardingStep();
  useEffect(() => {
    if (onboardingStep < 3) {
      navigate("/onboarding");
    }
  }, [navigate, onboardingStep]);

  const [sidePanelOpen, setSidePanelOpen] = useState(false);
  const toggleSidePanel = useCallback(() => setSidePanelOpen((prev) => !prev), []);

  return (
    <PageContainer>
      <TopBar
        icon={<WindowOutlined />}
        title={t("Product overview")}
        subtitle={
          <>
            {count} {t("products", { count })}
          </>
        }
        input={
          count > 0 ? (
            <TopBarInput
              selectedProducts={selectedProducts}
              isSelectMode={isSelectMode}
              onCreateDocuments={toggleSidePanel}
            />
          ) : undefined
        }
      />
      <div className="flex-grow flex flex-col gap-4 py-8">
        <Suspense fallback={<Loading />}>
          {count > 0 && (
            <>
              <MultiSelectActionBar
                products={products}
                selectedProducts={selectedProducts}
                isSelectMode={isSelectMode}
                toggleSelectMode={toggleSelectMode}
                toggleSelectAll={toggleSelectAll}
                resetSelectedProducts={resetSelectedProducts}
              />
              <DocumentsSidePanel
                selectedProducts={selectedProducts}
                open={sidePanelOpen}
                onClose={() => setSidePanelOpen(false)}
              />
              <Content>
                {products.map((item) => (
                  <ProductCard
                    key={item.id}
                    item={item}
                    productCategoriesMap={productCategoriesMap}
                    isSelectMode={isSelectMode}
                    isSelected={selectedProducts.has(item)}
                    onToggleSelect={() => toggleSelect(item)}
                  />
                ))}
              </Content>
            </>
          )}
          {count === 0 && <EmptyProductList activePlant={activePlant} />}
        </Suspense>
      </div>
    </PageContainer>
  );
};
